<template>
  <q-checkbox
    v-model="value"
    :disable="!edit"
    true-value="1"
    false-value="0"
  />
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: { default: '0' },
    edit: Boolean,
  },

  emits: ["update:modelValue"],

  setup(props, ctx) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        ctx.emit("update:modelValue", newValue);
      },
    });
    return {
      value,
    };
  },
};
</script>
