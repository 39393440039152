<template>
  <q-form
    class="q-gutter-md"
    @submit="$emit('submit')"
  >
    <q-input
      filled
      :label="usernameText"
      v-model="user"
      hide-bottom-space
      autocomplete="username"
      :error="!!loginError"
    />
    <q-input
      filled
      type="password"
      :label="passwordText"
      v-model="pwd"
      hide-bottom-space
      :error="!!loginError"
    />
    <div
      v-if="loginError"
      class="text-negative"
    >
      {{ loginError }}
    </div>
    <div>
      <q-btn
        unelevated
        rounded
        no-caps
        type="submit"
        color="primary"
        size="lg"
        class="full-width"
        :label="loginBtnText"
        :disable="!username || !password"
        :loading="loading"
      />
    </div>
  </q-form>
</template>

<script>
import { computed } from "vue";

export default {
  props: [
    "usernameText",
    "passwordText",
    "loginBtnText",
    "username",
    "password",
    "loading",
    "loginError",
  ],

  emits: ["submit", "update:username", "update:password"],

  setup(props, ctx) {
    const user = computed({
      get() {
        return props.username;
      },
      set(newValue) {
        ctx.emit("update:username", newValue);
      },
    });

    const pwd = computed({
      get() {
        return props.password;
      },
      set(newValue) {
        ctx.emit("update:password", newValue);
      },
    });

    return {
      user,
      pwd,
    };
  },
};
</script>
