import CrmDate from "../components/crm_date.vue";
import CrmSelect from "../components/crm_select.vue";
import CrmCheckbox from "../components/crm_checkbox.vue";

export function useFormControl(picklistValues, fieldRequiredText) {
  function formControl(fld) {
    switch (fld.type) {
      case "picklist":
      case "multipicklist":
        return CrmSelect;
      case "checkbox":
        return CrmCheckbox;
      case "date":
      case "birthday":
        return CrmDate;
      default:
        return "q-input";
    }
  }

  function fldProps(fld) {
    switch (fld.type) {
      case "picklist":
      case "multipicklist":
        return {
          options: picklistValues[fld.picklist_id],
          emitValue: true,
          mapOptions: true,
          multiple: fld.type === "multipicklist",
        };
      case "textarea":
        return {
          type: "textarea",
        };
      case "email":
        return {
          type: "email",
        };
      default:
        return {};
    }
  }

  function formControlProps(fld, instance) {
    return {
      modelValue: instance[fld.name],
      "onUpdate:modelValue": (v) => (instance[fld.name] = v),
      label: fld.label,
      hideBottomSpace: true,
      filled: true,
      rules: fld.mandatory
        ? [(val) => !!val || val === 0 || fieldRequiredText]
        : undefined,
      ...fldProps(fld),
    };
  }

  return { formControl, formControlProps };
}
