<template>
  <q-select
    v-if="edit"
    v-model="value"
    :options="options"
  />
  <q-field v-else>
    <template #control>
      <span>{{ currentValues.join(", ") }}</span>
    </template>
  </q-field>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: null,
    edit: Boolean,
    options: Array,
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const currentValues = computed(() => {
      return [].concat(props.modelValue ?? []).map((val) => {
        const opt = props.options.find((item) => item.value === val);
        return opt ? opt.label : val;
      });
    });
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        ctx.emit("update:modelValue", newValue);
      },
    });
    return {
      currentValues,
      value,
    };
  },
};
</script>
