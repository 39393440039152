<template>
  <q-input
    v-if="edit"
    v-model="value"
    :rules="rules"
    mask="##.##.####"
  >
    <template v-slot:append>
      <q-icon
        name="event"
        class="cursor-pointer"
      >
        <q-popup-proxy
          cover
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="value"
            mask="DD.MM.YYYY"
          >
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                label="Close"
                color="primary"
                flat
              />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>

  <q-field v-else>
    <template #control>
      <span>{{ value }}</span>
    </template>
  </q-field>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    edit: Boolean,
    modelValue: String,
    rules: {
      default: () => [],
    },
  },

  emits: ["update:modelValue"],

  setup(props, ctx) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        ctx.emit("update:modelValue", newValue);
      },
    });

    const rules = computed(() => {
      return [
        ...props.rules,
        (val) => !val || /^[0-3]\d\.[0-1]\d\.-?[\d]+$/.test(val),
      ];
    });

    return {
      value,
      rules,
    };
  },
};
</script>
